<template>
  <b-card class="row form-data no-border" body-class="p-0 p-md-3">
    <section class="text-center my-4" v-if="!is_resposible_logged">
      <img src="@/assets/images/waiting.png" height="80" class="my-4" />
      <p class="text-muted">
        Aguradando preenchimento do formulário pelo responsável legal
      </p>

      <strong v-if="legal_responsible && legal_responsible.email">
        <b-icon icon="envelope" /> {{ legal_responsible.email }}
      </strong>
      <p v-if="legal_responsible.name === ''" class="text-info">Pendente</p>
      <b-button
        :disabled="isLoading"
        class="d-flex mx-auto mt-4 align-items-center"
        @click="handleResendInvite()"
      >
        <b-spinner small v-if="isLoading" class="mr-1" />
        <span> {{ isLoading ? "Reeviando" : "Reenviar e-mail" }}</span>
      </b-button>
    </section>
    <b-form
      :form="form"
      v-if="is_resposible_logged"
      @submit.prevent="handleSubmit"
    >
      <div class="row">
        <h5 class="mb-4 col-md-12">
          <strong>Dados do Reponsável Legal</strong>
        </h5>
        <div class="col-md-4">
          <b-form-group label="CPF">
            <b-form-input
              v-model="$v.form.cpf.$model"
              :class="$v.form.cpf.$error && 'is-invalid'"
              v-mask="'###.###.###-##'"
              placeholder="CPF"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.cpf.cpf || !$v.form.cpf.required"
            >
              Digite um CPF valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Nome">
            <b-form-input
              :class="$v.form.name.$error && 'is-invalid'"
              v-model="$v.form.name.$model"
              placeholder="Nome"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required">
              Digite um nome válido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Nacionalidade">
            <multiselect
              close-on-select
              :show-labels="false"
              :class="$v.form.nationality.$error && 'is-invalid'"
              v-model="form.nationality"
              :options="countries"
              placeholder="Selecione sua nacionalidade"
              label="name"
            >
            </multiselect>
            <b-form-invalid-feedback v-if="!$v.form.nationality.required">
              Selecione sua nacionalidade
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Profissão">
            <b-form-input
              :class="$v.form.profession.$error && 'is-invalid'"
              v-model="$v.form.profession.$model"
              placeholder="Profissão"
            />
            <b-form-invalid-feedback v-if="!$v.form.profession.required">
              Digite sua profissão
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="RG">
            <b-form-input
              :class="$v.form.rg.$error && 'is-invalid'"
              v-model="$v.form.rg.$model"
              placeholder="RG"
              @keypress="isNumber($event)"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.rg.required || !$v.form.rg.maxLength"
            >
              RG inválido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Estado Civil">
            <multiselect
              :close-on-select="true"
              :show-labels="false"
              :class="$v.form.marital_status.$error && 'is-invalid'"
              v-model="$v.form.marital_status.$model"
              :options="marital_status"
              placeholder="Selecione seu estado civil"
              label="name"
            >
            </multiselect>
            <b-form-invalid-feedback v-if="!$v.form.marital_status.required">
              Selecione seu estado civil
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Gênero">
            <multiselect
              :close-on-select="true"
              :show-labels="false"
              :class="$v.form.gender.$error && 'is-invalid'"
              v-model="$v.form.gender.$model"
              :options="genders"
              placeholder="Selecione seu Gênero"
              label="name"
            >
            </multiselect>
            <b-form-invalid-feedback v-if="!$v.form.gender.required">
              Selecione seu sexo
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="E-mail">
            <b-form-input disabled v-model="form.email" placeholder="E-mail" />
          </b-form-group>
        </div>
        <!-- Endereço -->
        <hr />
        <div class="px-3 row">
          <div class="col-md-4">
            <b-form-group label="CEP" class="input-loading">
              <b-form-input
                autocomplete="off"
                v-model="$v.form.zip_code.$model"
                :class="
                  ($v.form.zip_code.$error || invalid_cep) && 'is-invalid'
                "
                v-mask="'#####-###'"
                @keyup="getZipCode"
                placeholder="CEP"
              />
              <b-icon icon="disc" v-if="loading_zip_code" />
              <b-form-invalid-feedback
                v-if="!$v.form.zip_code.required || invalid_cep"
              >
                CEP inválido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Logradouro">
              <b-form-input
                :disabled="!form.zip_code && !form.zip_code"
                :class="$v.form.place.$error && form.zip_code && 'is-invalid'"
                v-model="form.place"
                placeholder="Logradouro"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.place.required && form.zip_code"
              >
                Digite o logradouro
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Bairro">
              <b-form-input
                :class="
                  $v.form.neighborhood.$error && form.zip_code && 'is-invalid'
                "
                v-model="form.neighborhood"
                :disabled="!form.zip_code && !form.neighborhood"
                placeholder="Bairro"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.neighborhood.required && form.zip_code"
              >
                Digite o seu bairro
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Cidade">
              <b-form-input
                v-model="form.city"
                :class="$v.form.city.$error && form.zip_code && 'is-invalid'"
                :disabled="!form.zip_code && !form.city"
                placeholder="Cidade"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.city.required && form.zip_code"
              >
                Digite a sua cidade
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="UF">
              <b-form-input
                v-model="form.state"
                :class="$v.form.state.$error && form.zip_code && 'is-invalid'"
                :disabled="!form.zip_code && !form.state"
                placeholder="UF"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.state.required && form.zip_code"
              >
                Digite o seu estado
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Número">
              <b-form-input
                :class="$v.form.number.$error && form.zip_code && 'is-invalid'"
                @keypress="isNumber($event)"
                v-model="form.number"
                placeholder="Número"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.number.required && form.zip_code"
              >
                Digite o número
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Complemento">
              <b-form-input
                v-model="form.complement"
                placeholder="Complemento"
              />
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex w-100 justify-content-between">
        <b-button variant="light" @click="handlePreviousStep()">
          Voltar
        </b-button>
        <b-button :disabled="loading_submit" type="submit" variant="primary">
          <b-spinner v-if="loading_submit" small class="mr-2" />
          <span>Salvar e avançar</span>
        </b-button>
      </div>
    </b-form>
  </b-card>
</template>
<script>
import { api } from "@/services/api";
import { isValidCpf } from "@/utils/validations/cpf";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import countries from "@/utils/countries.json";
import { isNumber } from "@/utils/validations/isNumber.js";
import { normalize } from "@/utils/normalize";

export default {
  mixins: [validationMixin],
  directives: { mask },
  props: {
    step: {
      type: Number,
    },
    legal_responsible: {
      type: Object,
    },
    inboundProcess: {
      type: Number,
    },
    form_data: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      is_resposible_logged: true,
      marital_status: [],
      legal_personnels: [],
      countries: [],
      genders: [],
      isNumber,
      form: {
        name: null,
        nationality: null,
        profession: null,
        rg: null,
        cpf: null,
        marital_status: null,
        gender: null,
        email: null,
        is_main: true,
        legal_personnel: null,
        zip_code: null,
      },
      entrerpise_data: null,
      responsible_data: null,
      processingDelete: false,
      isBusy: true,
      loadingImage: false,
      imageUrl: "",
      loading_submit: false,
      loading_cnpj: false,
      loading_zip_code: false,
      invalid_cep: false,
    };
  },
  validations: {
    form: {
      cpf: {
        required,
        cpf: isValidCpf,
      },
      name: { required },
      nationality: { required },
      profession: { required },
      rg: { required, maxLength: maxLength(12) },
      marital_status: { required },
      gender: { required },
      email: { required },
      zip_code: { required },
      state: { required },
      number: { required },
      place: { required },
      neighborhood: { required },
      city: { required },
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["get_marital_status", "get_genders", "recover"]),
    getZipCode() {
      let zipCode = this.form.zip_code;
      if (zipCode) {
        zipCode = zipCode.replace(/[^\d]/g, "");
        if (zipCode.length === 8) {
          this.loading_zip_code = true;
          api
            .get(`/homologation/get-zip-code/${zipCode}/`)
            .then(({ data }) => {
              if (data.erro) {
                this.invalid_cep = true;
              } else {
                this.invalid_cep = false;
              }
              this.responsible_data = data;
              this.form = {
                ...this.form,
                city: this.responsible_data.localidade,
                state: this.responsible_data.uf,
                place: this.responsible_data.logradouro,
                neighborhood: this.responsible_data.bairro,
              };
              this.loading_zip_code = false;
            })
            .catch(() => {
              this.loading_zip_code = false;
            });
        }
      }
    },
    handleResendInvite() {
      this.isLoading = true;
      const { email } = this.legal_responsible;
      this.recover({ email }).then(() => {
        this.isLoading = false;
        this.show_toast({
          message: "E-mail reenviado com sucesso",
          type: "success",
        });
      });
    },
    async handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading_submit = true;
        await api
          .patch(`/register/${this.inboundProcess}/`, this.getFormDataObject())
          .then(({ status }) => {
            this.loading_submit = false;
            if (status === 200) {
              this.$emit("update_inbound");
            }
          });
      }
    },
    getFormDataObject() {
      const objectForm = {
        inbound_process: this.inboundProcess,
        legal_responsible: {
          name: this.form.name,
          nationality: this.form.nationality.name,
          profession: this.form.profession,
          rg: this.form.rg?.replace(/[^\d]/g, ""),
          cpf: this.form.cpf?.replace(/[^\d]/g, ""),
          marital_status: this.form.marital_status.id,
          gender: this.form.gender.id,
          email: this.form.email,
          is_main: true,
          address: {
            place: this.form.place,
            number: this.form.number,
            complement: this.form.complement,
            zip_code: this.form.zip_code?.replace(/[^\d]/g, ""),
            neighborhood: this.form.neighborhood,
            city: this.form.city,
            state: this.form.state,
            country: this.form.nationality.name,
          },
        },
      };
      return objectForm;
    },
    resetForm(form) {
      for (const member in form) {
        delete form[member];
      }
    },

    handlePreviousStep() {
      this.$emit("previous_update_inbound");
    },
    getMarital() {
      this.get_marital_status().then(({ results }) => {
        this.marital_status = results;
      });
    },
    getGenders() {
      this.get_genders().then(({ results }) => {
        this.genders = results;
      });
    },
  },
  mounted() {
    this.countries = countries;
    if (
      this.legal_responsible?.email === this.user.email ||
      !this.legal_responsible
    ) {
      this.is_resposible_logged = true;
    } else {
      this.is_resposible_logged = false;
    }

    this.form.email = this.user.email;
    this.getMarital();
    this.getGenders();
  },

  watch: {
    form_data(value) {
      if (value && value.cpf) {
        this.form = value;
        this.form.zip_code = value.address.zip_code;
        this.form.complement = value.address.complement;
        this.form.number = value.address.number;

        this.form.nationality = countries.find(
          (country) => normalize(value.nationality) === normalize(country.name)
        );

        this.getZipCode();
      }
    },
  },
};
</script>
