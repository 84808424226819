<template>
  <b-card class="row form-data no-border" body-class="p-0 p-md-3">
    <timeline-steps :steps="steps" :current_step="step" />
    <Form
      :legal_responsible="form_legal_responsible"
      :form_data="form_legal_person"
      v-if="step.order === 1"
      @update_inbound="getInboudProcess()"
      :inboundProcess="inboundProcess"
    />
    <FormResponsible
      :form_data="form_legal_responsible"
      v-if="step.order === 2"
      @update_inbound="getInboudProcess()"
      @previous_update_inbound="handlePreviousInboudProcess()"
      :inboundProcess="inboundProcess"
      :legal_responsible="legal_responsible"
    />
    <Nda
      v-if="step.order === 3 && !isFinished"
      @update_inbound="getInboudProcess()"
      @previous_update_inbound="handlePreviousInboudProcess()"
      :inboundProcess="inboundProcess"
      :legal_responsible="legal_responsible"
    />
    <Finish v-if="isFinished" />
  </b-card>
</template>
<script>
import { mapActions } from "vuex";
import TimelineSteps from "@/components/TimelineSteps";
import Form from "./form";
import FormResponsible from "./formResponsible";
import Nda from "./nda";
import Finish from "./finish";

export default {
  components: {
    Finish,
    Form,
    TimelineSteps,
    FormResponsible,
    Nda,
  },
  data() {
    return {
      form_legal_person: {},
      form_legal_responsible: {},
      isFinished: false,
      inboundProcess: null,
      legal_responsible: null,
      legal_personnel: null,
      step: {},
      steps: [],
    };
  },
  methods: {
    ...mapActions([
      "get_inboud_process",
      "go_to_previous_step",
      "get_step_data",
    ]),
    getInboudProcess() {
      this.get_inboud_process().then(({ results }) => {
        if (results[0].current_step) {
          this.isFinished = false;
          const {
            current_step,
            id,
            legal_responsible,
            legal_personnel,
          } = results[0];
          this.inboundProcess = id;
          this.step = current_step;
          this.legal_responsible = legal_responsible;
          this.legal_personnel = legal_personnel;
          this.steps = results[0].steps;
          this.getStepData();
        } else {
          this.isFinished =
            [0, 1, 2, 3].includes(results[0].current_step) ||
            !results[0].current_step;
        }
      });
    },
    getStepData() {
      this.get_step_data(this.inboundProcess).then((data) => {
        this.form_legal_person = data.legal_person;
        this.form_legal_responsible = data.legal_responsible;
      });
    },
    handlePreviousInboudProcess() {
      this.go_to_previous_step(this.inboundProcess).then(({ status }) => {
        if (status === 200) {
          this.getInboudProcess();
        }
      });
    },
  },
  mounted() {
    this.getInboudProcess();
  },
  beforeDestroy() {
    window.location.reload();
  },
};
</script>
