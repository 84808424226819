<template>
  <b-card
    class="no-border"
    :no-body="preview"
    body-class="p-0 p-md-4"
    :class="preview ? 'border-0' : ''"
  >
    <div class="text-center" :class="preview ? 'mt-4' : 'my-4'">
      <h3 class="title no-print">MAAT GESTÃO</h3>
      <img
        src="@/assets/images/logo.svg"
        alt=""
        class="mx-auto d-block print"
      />
      <strong>Termo de Confidencialidade</strong>
    </div>
    <div class="d-flex alingn-items-center justify-content-end mb-2">
      <b-button
        @click="savePdf()"
        variant="light"
        class="px-2 ml-1 d-flex align-items-center no-print gap-2"
        v-b-popover.hover.top="'Imprimir'"
      >
        <b-icon icon="printer"></b-icon>
        <span>Imprimir</span>
      </b-button>
    </div>
    <div
      ref="terms"
      id="terms"
      class="terms-of-conditions"
      v-if="data && data.legal_personnel"
    >
      <p>Das partes,</p>
      <p>
        <strong>{{ data.legal_personnel.social_reason }}</strong
        >, com sede na
        <span v-if="data.legal_personnel && data.legal_personnel.address">
          {{ data.legal_personnel.address.place }}, n°
          {{ data.legal_personnel.address.number }},
          {{
            data.legal_personnel.address.complement
              ? data.legal_personnel.address.complement + ", "
              : ""
          }}
          {{ data.legal_personnel.address.neighborhood }}, {{ data.city }}
          {{ data.legal_personnel.address.state }}, CEP
          {{ data.legal_personnel.address.zip_code | VMask("#####-###") }}
        </span>
        inscrita no CNPJ sob o n°
        {{ data.legal_personnel.cnpj | VMask("##.###.###/####-##") }}, neste ato
        representado por <strong> {{ data.legal_responsible.name }}</strong
        >,
        {{
          data.legal_responsible.marital_status.name
            .split("")
            .slice(0, -1)
            .join("")
        }}{{ data.legal_responsible.is_male ? "o" : "a" }},
        {{ data.legal_responsible.profession }}, portador da cédula de
        Identidade n° {{ data.legal_responsible.rg }}, CPF/MF n°
        {{ data.legal_responsible.cpf | VMask("###.###.###-##") }}, domiciliado
        no mesmo endereço da {{ data.legal_responsible.address.place }}, n°
        {{ data.legal_responsible.address.number }},
        {{
          data.legal_responsible.address.complement
            ? `${data.legal_responsible.address.complement},`
            : ""
        }}
        {{ data.legal_responsible.address.neighborhood }},
        {{ data.legal_responsible.address.city }} -
        {{ data.legal_responsible.address.state }} CEP
        {{ data.legal_responsible.address.zip_code | VMask("#####-###") }},
        neste ato representada na forma de seu contrato social, doravante
        simplesmente denominada de
        <strong>
          {{
            data.legal_personnel.fantasy_name
              ? data.legal_personnel.fantasy_name
              : data.legal_personnel.social_reason
          }}
        </strong>
        .
      </p>

      <p>
        Souza e Lima Serviços Empresariais LTDA, com sede na Avenida Paulista,
        no 726, Conjunto 1707, 17o andar, Bairro Bela Vista, CEP 01.310-910,
        Município de São Paulo, Estado de São Paulo, inscrita no CNPJ sob o n°
        39.469.773/0001-57, neste ato representado pelo senhor Luiz Fernando de
        Lima Paulo, brasileiro, casado, empresário, portador da cédula de
        Identidade no 33.463.808-2, CPF/MF no 297.621.268-66, domiciliado no
        mesmo endereço supracitado, neste ato representada na forma de seu
        contrato social, doravante simplesmente denominada de “Souza e Lima
        Serviços Empresariais LTDA”.
      </p>

      <p>
        As partes acima identificadas têm, entre si, justo e acertado o presente
        termo de confidencialidade (“termo”), que se regerá pelas cláusulas
        seguintes e condições descritas no presente:
      </p>
      <p>
        <strong>1.</strong> Para o propósito deste termo serão consideradas
        informações confidenciais todas e quaisquer informações e/ou dados
        relacionados aos negócios realizados pela ou em favor de qualquer uma
        das partes, incluindo sem limitação, informações financeiras, contábeis,
        trabalhistas, operacionais, jurídicas, estratégicas, comerciais,
        econômicas, técnicas, ambientais, de engenharia, cópia ou registros dos
        mesmos, orais, eletrônicos ou escritos, contidos em qualquer meio
        físico, que tenham sido fornecidos, transmitidos ou divulgado por uma
        parte a outra oralmente, eletronicamente, ou por escrito, em função
        desse termo, independentemente desse termo, independente de tais
        informações confidenciais terem sido fornecidas diretamente por uma
        parte a outra ou terem sido fornecidas por quaisquer terceiros, em nome
        de uma das partes.
      </p>
      <p>
        <strong>1.1.</strong> Consideram-se especialmente sensíveis e, por tal
        razão, devendo ser tratadas com rigorosa confidencialidade qualquer
        informação específica sobre o(s) objeto(s) da contratação(es),
        inclusive, mas não se limitando, a suas características físicas,
        técnicas, localização, funcionamento e destinação, início de produção,
        construção, atividades, bem como outros dados que permitam a
        identificação ou individualização do(s) objeto(s) contratado(s) por
        terceiros não contratantes devendo as partes agirem de forma a impedir,
        inclusive, a identificação por estes últimos, dos demais envolvidos
        nesta contratação.
      </p>

      <p>
        <strong>2.</strong> O termo “informações confidenciais” não inclui
        informações que: estejam ou venham a ser tornar públicas ou disponíveis
        ao público em geral por qualquer razão que não seja com violação dos
        presentes termos, ou estavam ou se tornaram legalmente disponíveis para
        as partes através de terceiros, desde que tais terceiros não estivessem
        obrigados, por contrato, de ofício ou por força de lei, regulamento ou
        estatuto, a manter tais informações sob confidencialidade, e desde que
        as informações tenham sido obtidas pelos terceiros de forma lícita.
      </p>
      <p>
        <strong>3.</strong> As partes concordam que, sem o consentimento por
        escrito da outra parte deverão tratar as informações confidenciais como
        confidenciais e não poderão divulgar quaisquer das informações
        confidenciais da outra parte, exceto para administradores, empregados,
        prepostos, consultores subcontratados (“representantes”), desde que
        sejam seus representantes credenciados e que necessitem ter acesso as
        informações confidenciais para o desenvolvimento das negociações entre
        as partes. As partes farão com que seus representantes tomem
        conhecimento do presente termo e se comprometam a observá-lo, ficando
        responsável solidariamente com estes pelo mau uso das informações
        confidenciais.
      </p>
      <p><strong>4.</strong> As partes concordam ainda:</p>
      <p>
        <strong>4.1.</strong> não utilizar as informações confidenciais
        recebidas da outra parte para qualquer outro fim que não seja a
        atividade estabelecida para a realização dos serviços entabulados pelas
        partes;
      </p>

      <p>
        <strong>4.2.</strong> não utilizar, revelar ou divulgar a existência e
        ou quaisquer dos termos das negociações entre as partes (“negociações”);
      </p>
      <p>
        <strong>4.3.</strong> assegurar que toda e qualquer parte envolvida ou
        acionada por conta das atividades abarcadas por este termo estejam
        informados das obrigações constantes deste termo, e assegurar a
        observância de tais obrigações;
      </p>
      <p>
        <strong>4.4.</strong> apenas dar conhecimento deste termo, seus termos e
        contratações que se seguirem a terceiros, ainda que essenciais a
        realização deste negócio, após obter a prévia e expressa autorização por
        escrito da parte contrária para este fim;
      </p>
      <p>
        <strong>4.5.</strong> não divulgar a existência do presente termo, bem
        como de seus termos e condições a quaisquer terceiros, exceto para os
        representantes que estejam diretamente envolvidos nas negociações e
        tenham aderido ao presente termo; e
      </p>
      <p>
        <strong>5.</strong> Nenhuma parte poderá ser responsabilizada pela
        eventual divulgação de informações confidenciais ou das negociações que
        decorra de determinação judicial desde que: a divulgação não tenha sido
        causada por uma divulgação de informações confidenciais ou das
        negociações proibidas por este termo; por divulgação das informações
        confidenciais ou das negociações seja limitada à extensão da exigência.
        Se as partes forem obrigadas, por determinação de autoridade pública,
        lastreada em norma ou ordem judicial, a divulgar ou revelar qualquer
        item das negociações ou das informações confidenciais deverão notificar
        a parte contrária de tal ordem, por escrito, imediatamente após o
        recebimento de tal determinação ou ordem judicial, só efetivando essa
        divulgação com o devido conhecimento da parte contrária do teor das
        informações confidenciais a serem divulgadas.
      </p>
      <p>
        <strong>6.</strong> O presente termo entra em vigor na data de sua
        assinatura e permanecerá em vigor por 10 (dez) anos, contados da data de
        término das negociações entre as partes. Na hipótese de as negociações
        resultarem em contratação específica, ainda que sem cláusula de
        confidencialidade, o prazo de vigência do presente termo se estenderá a
        10 (dez) anos após o término da contratação.
      </p>
      <p>
        <strong>7.</strong> Ressalvadas as hipóteses contidas no item 6 supra, a
        parte que permitir, tolerar for conivente ou, ainda que por inocente
        inação, der causa a divulgação desta negociação ou daquelas que se
        seguirem a assinatura deste termo, responderá perante a parte inocente
        pelos danos que causar, desde já se estabelecendo como mínimo
        indenizatório o valor de R$ 500.000,00 (quinhentos mil reais), devidos
        independente de prova de danos à imagem e reputação da parte inocente.
      </p>
      <p>
        <strong>8.</strong> A eventual abstenção pelas partes no exercício de
        quaisquer direitos que lhes assistam pelo termo ora firmado não afetará,
        de modo algum, estes direitos, que poderão ser exercidos a qualquer
        momento.
      </p>
      <p>
        <strong>9.</strong> Este termo poderá ser alterado por escrito, através
        de aditivo contratual, firmado por todas as partes, não podendo qualquer
        dos direitos e obrigações nele contido ser cedido ou transferido para
        terceiros sem a expressa concordância das demais partes.
      </p>
      <p>
        <strong>10.</strong> O presente termo obriga as partes e/ou seus
        sucessores, a qualquer título.
      </p>
      <p>
        <strong>11.</strong> A nulidade, invalidade ou ineficácia de quaisquer
        das avenças contidas neste termo não prejudicará a eficácia e validade
        das demais, que serão integralmente cumpridas.
      </p>
      <p>
        <strong>12.</strong> As partes elegem o foro da comarca de São Paulo/SP
        para dirimir qualquer controvérsia e ou dúvida decorrente deste termo,
        ou qualquer termo aditivo que venha a ser celebrado, com renúncia
        expressa de qualquer outro, por mais privilegiado que seja.
      </p>
      <br /><br />
      <p>
        E por estarem assim acordadas, as partes assinam este termo em duas vias
        de igual teor e forma, para fins de direito, na presença de duas
        testemunhas.
      </p>
      <p class="text-right">
        <strong>São Paulo, {{ day }} de {{ month }} de {{ year }}</strong>
      </p>

      <div class="d-flex align-items-center justify-content-between print my-5">
        <div class="w-100 mr-5">
          <hr />
          <span>Assinatura da parte Prestadora</span>
        </div>
        <div class="w-100 ml-5">
          <hr />
          <span>Assinatura da parte Tomadora</span>
        </div>
      </div>
    </div>
    <b-modal id="send-nda-email" title="Enviar termo para o e-mail" centered>
      <b-form-group :label="$t('general.email')">
        <b-form-input placeholder="email@email.com" autofocus> </b-form-input>
      </b-form-group>
    </b-modal>

    <div
      v-if="!preview"
      class="d-flex my-4 align-items-center justify-content-between no-print"
    >
      <b-form-group class="pl-0">
        <b-form-checkbox class="pl-0" v-model="accepted_terms">
          Eu li e concordo com os termos de uso
        </b-form-checkbox>
      </b-form-group>
    </div>
    <div
      v-if="!preview"
      class="mt-3 d-flex w-100 justify-content-between no-print"
    >
      <b-button variant="light" @click="handlePreviousStep()">
        Voltar
      </b-button>
      <b-button
        :disabled="loading_submit"
        variant="primary"
        v-if="accepted_terms"
        @click="handleAcceptNDA()"
      >
        <b-spinner v-if="loading_submit" small class="mr-2" />
        <span>Continuar</span>
      </b-button>
    </div>
  </b-card>
</template>
<script>
import { api } from "@/services/api";
import { mapGetters } from "vuex";
import { printPage } from "@/utils/printPage";

export default {
  data() {
    return {
      loading_submit: false,
      accepted_terms: false,
      data: {},
      day: new Date().getDate(),
      month: this.getMouth(),
      year: new Date().getFullYear(),
    };
  },
  props: {
    legal_responsible: {
      type: Object,
    },
    inboundProcess: {
      type: Number,
    },
    preview: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getMouth() {
      const indexMounths = new Date().getMonth();
      const mounths = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return mounths[indexMounths];
    },

    savePdf() {
      const contentHtml = `
      <h3 class="title d-block text-center mx-auto">MAAT GESTÃO</h3>
        <img 
          alt=""
          id="image-pdf"
          height="60"
        />
      <strong class="d-block text-center">Termo de Confidencialidade</strong>
      ${this.$refs.terms.innerHTML}
      `;
      printPage(
        contentHtml,
        `Termo de confidencialidade - ${this.data.legal_personnel.social_reason}`
      );
    },
    handleAcceptNDA() {
      this.loading_submit = true;
      api
        .patch(`/register/${this.inboundProcess}/`, {
          nda: true,
          inbound_process: this.inboundProcess,
        })
        .then(() => {
          this.loading_submit = false;
          this.$emit("update_inbound");
        });
    },
    handlePreviousStep() {
      this.$emit("previous_update_inbound");
    },
  },
  mounted() {
    api
      .get(`/nda/?legal_responsible=${this.legal_responsible.id}`)
      .then(({ data }) => {
        this.data = data.results[0];
      });
  },
};
</script>
<style lang="scss">
.terms-of-conditions {
  height: 465px;
  overflow: auto;
  background: white;
  color: #2f3136;
  box-shadow: inset 0px 0px 14px 4px #eee;
  padding: 2em;
}
</style>
