import { api } from "@/services/api";

const getCnpj = async (cnpj) => {
  const onlyNumbersCnpj = cnpj.replace(/[^\d]/g, "");
  const { data } = await api.get(`/homologation/get-cnpj/${onlyNumbersCnpj}`, {
    ignore: true,
  });

  return data;
};

export { getCnpj };
