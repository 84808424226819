import { render, staticRenderFns } from "./formResponsible.vue?vue&type=template&id=8578478c&"
import script from "./formResponsible.vue?vue&type=script&lang=js&"
export * from "./formResponsible.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports