<template>
  <div id="svg-finish">
    <svg
      version="1.1"
      width="100"
      height="100"
      viewBox="0 0 496 496"
      style="enable-background: new 0 0 100 100"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="m112 392h144v64h-144zm0 0" class="icons-primary" />
        <path d="m32 216h160v104h-160zm0 0" class="icons-primary" />
        <path d="m464 256h-160v-48h160zm0 0" class="icons-primary" />
        <path d="m464 320h-160v-48h160zm0 0" class="icons-primary" />
        <path d="m192 192h-160v-128h160zm0 0" class="icons-primary" />
      </g>
      <path
        d="m496 176h-16v-32h-32v-48h-64v-96h-384v394.894531l84.246094 101.105469h299.753906v-64h112zm-32 0h-160v-16h160zm-32-32h-96v-32h96zm-352 321.902344-54.921875-65.902344h54.921875zm288 14.097656h-272v-96h-80v-368h352v80h-48v48h-32v32h-16v256h96zm40-64h-48v-40c0-13.230469 10.769531-24 24-24s24 10.769531 24 24zm72 0h-56v-40c0-22.054688-17.945312-40-40-40s-40 17.945312-40 40v40h-56v-224h192zm0 0"
        class="text-primary"
      />
      <path
        d="m182.855469 36.121094-13.710938-8.242188-68.191406 113.648438-25.378906-12.6875-7.148438 14.320312 38.621094 19.3125zm0 0"
        class="text-primary"
      />
      <path d="m352 208h16v48h-16zm0 0" class="text-primary" />
      <path d="m400 208h16v48h-16zm0 0" class="text-primary" />
      <path d="m400 272h16v48h-16zm0 0" class="text-primary" />
      <path d="m352 272h16v48h-16zm0 0" class="text-primary" />
      <path d="m336 448h16v16h-16zm0 0" class="text-primary" />
      <path d="m112 448h208v16h-208zm0 0" class="text-primary" />
      <path d="m112 416h144v16h-144zm0 0" class="text-primary" />
      <path d="m112 384h144v16h-144zm0 0" class="text-primary" />
      <path d="m32 208h160v16h-160zm0 0" class="text-primary" />
      <path d="m32 240h16v16h-16zm0 0" class="text-primary" />
      <path d="m64 240h128v16h-128zm0 0" class="text-primary" />
      <path d="m32 272h160v16h-160zm0 0" class="text-primary" />
      <path d="m32 304h128v16h-128zm0 0" class="text-primary" />
      <path d="m176 304h16v16h-16zm0 0" class="text-primary" />
    </svg>

    <h4 class="text-center">Seu cadastro foi finalizado com sucesso!</h4>
    <p class="text-center">
      Você sera redirecionado para o sistema em
      <strong>{{ timeleft }}</strong> segundos
    </p>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      timeleft: 5,
    };
  },
  methods: {
    ...mapActions(["get_user"]),
    rulesRedirects() {
      if (this.user.enterprise?.is_provider) {
        return this.$router.push("/service/list");
      }
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["user", "has_pending_inbound_process"]),
  },
  mounted() {
    if (!this.has_pending_inbound_process) {
      this.rulesRedirects();
    } else {
      this.get_user({ id: this.user.id }).then(() => {
        const downloadTimer = setInterval(() => {
          if (this.timeleft === 0) {
            clearInterval(downloadTimer);
            this.rulesRedirects();
          } else {
            this.timeleft -= 1;
          }
        }, 1000);
      });
    }
  },
};
</script>
<style lang="scss" scoped>
#svg-finish {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    display: block;
    margin: 5em auto 2em;
  }
  .icons-primary {
    fill: $secondary;
  }
  .text-primary {
    fill: $text-color;
  }
}
</style>
