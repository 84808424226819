<template>
  <b-card class="row form-data no-border" body-class="p-0 p-md-3">
    <section class="text-center my-4" v-if="!is_resposible_logged">
      <img src="../../assets/images/waiting.png" height="100" class="my-4" />
      <p class="text-muted">
        Aguradando preenchimento do formulário pelo responsável legal
      </p>
      <strong v-if="legal_responsible && legal_responsible.email"
        ><b-icon icon="envelope" /> {{ legal_responsible.email }}</strong
      >
    </section>
    <b-form
      :form="form"
      @submit.prevent="handleSubmit"
      v-if="is_resposible_logged"
    >
      <h5 class="mb-4">
        <strong>Dados da Empresa</strong>
      </h5>
      <!-- Empresa -->
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="CNPJ" class="input-loading">
            <b-input
              v-model="$v.form.cnpj.$model"
              :class="invalid_cnpj && 'is-invalid'"
              v-mask="'##.###.###/####-##'"
              @keyup="handleGetCnpj"
              placeholder="CNPJ"
            />
            <b-icon icon="disc" v-if="loadingCnpj" />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Razão Social">
            <b-form-input
              disabled
              v-model="form.social_reason"
              placeholder="Razão Social"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Nome Fantasia">
            <b-form-input
              disabled
              v-model="form.fantasy_name"
              placeholder="Nome Fantasia"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Atividade Principal">
            <b-form-input
              disabled
              v-model="form.segment"
              placeholder="Atividade Principal"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="CEP" class="input-loading">
            <b-form-input
              v-model="form.zip_code"
              @keyup="getZipCode"
              :class="$v.form.zip_code.$error && 'is-invalid'"
              v-mask="'#####-###'"
              placeholder="CEP"
            />
            <b-icon icon="disc" v-if="loading_zip_code" />
            <b-form-invalid-feedback v-if="$v.form.zip_code.$error">
              O CEP é obrigtório
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Cidade">
            <b-form-input
              v-model="form.city"
              :class="$v.form.city.$error && 'is-invalid'"
              placeholder="Cidade"
            />
            <b-form-invalid-feedback v-if="$v.form.city.$error">
              A Cidade é obrigtória
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="UF">
            <b-form-input
              v-model="form.state"
              :class="$v.form.state.$error && 'is-invalid'"
              placeholder="UF"
            />
            <b-form-invalid-feedback v-if="$v.form.state.$error">
              O Estado é obrigtório
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Logradouro">
            <b-form-input
              v-model="form.place"
              disabled
              placeholder="Logradouro"
            />
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group label="Número">
            <b-form-input
              v-model="form.number"
              :class="$v.form.number.$error && 'is-invalid'"
              placeholder="Número"
            />
            <b-form-invalid-feedback v-if="$v.form.number.$error">
              O Número é obrigtório
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group label="Complemento">
            <b-form-input v-model="form.complement" placeholder="Complemento" />
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Bairro">
            <b-form-input
              v-model="form.neighborhood"
              :class="$v.form.neighborhood.$error && 'is-invalid'"
              placeholder="Bairro"
            />
            <b-form-invalid-feedback
              v-if="
                $v.form.enterprise_email.$error ||
                  !$v.form.enterprise_email.email
              "
            >
              Adicione um Bairro
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Contato">
            <b-form-input
              v-model="form.phone"
              :class="$v.form.phone.$error && 'is-invalid'"
              placeholder="Contato"
            />
            <b-form-invalid-feedback v-if="$v.form.phone.$error">
              Adicione um Contato
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Email">
            <b-form-input
              v-model="$v.form.enterprise_email.$model"
              :class="
                ($v.form.enterprise_email.$error ||
                  !$v.form.enterprise_email.email) &&
                  'is-invalid'
              "
              placeholder="empresa@email.com"
            />
            <b-form-invalid-feedback
              v-if="
                $v.form.enterprise_email.$error ||
                  !$v.form.enterprise_email.email
              "
            >
              {{ $t("auth.type-valid-email") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-3" v-if="!is_resposible || !is_resposible_logged">
          <b-form-group label="E-mail do responsável legal">
            <b-form-input
              :v-model="$v.email.$model"
              :class="!$v.email.email && 'is-invalid'"
              v-model="email"
              placeholder="E-mail"
              :disabled="invalid_cnpj || $v.form.cnpj.$error"
            />
            <b-form-invalid-feedback v-if="!$v.email.email">
              {{ $t("auth.type-valid-email") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <b-form-group
        class="pl-0"
        :description="
          email
            ? 'O Campo e-mail do responsável foi preenchido ao marcar essa opção ele será apagado'
            : ''
        "
      >
        <b-form-checkbox
          class="pl-0"
          v-model="is_resposible"
          @change="email = ''"
          :disabled="
            disableCheck || !!(is_resposible_logged && legal_responsible)
          "
        >
          Sou o responsável legal
        </b-form-checkbox>
      </b-form-group>
      <div class="mt-3 d-flex w-100 justify-content-end">
        <b-button :disabled="loadingSubmit" type="submit" variant="primary">
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Salvar e avançar</span>
        </b-button>
      </div>
    </b-form>
  </b-card>
</template>
<script>
import { api } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { getCnpj as fetchCNPJ } from "@/utils/validations/getCnpj.js";
import _ from "lodash";

export default {
  mixins: [validationMixin],
  directives: { mask },
  props: {
    step: {
      type: Number,
    },
    inboundProcess: {
      type: Number,
    },
    form_data: {
      type: Object,
    },
    legal_responsible: {
      type: Object,
    },
  },
  data() {
    return {
      is_resposible_logged: true,
      is_resposible: false,
      disableCheck: false,
      invalid_cnpj: false,
      valid_cnpj: false,
      email: null,
      form: {
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
      },
      entrerpise_data: null,
      enterprise_email: null,
      loadingSubmit: false,
      loadingCnpj: false,
      loading_zip_code: false,
    };
  },
  validations: {
    email: {
      email: email,
    },

    form: {
      cnpj: { required },
      social_reason: { required },
      segment: { required },
      zip_code: { required },
      city: { required },
      state: { required },
      place: { required },
      number: { required },
      neighborhood: { required },
      phone: { required },
      enterprise_email: {
        required,
        email: email,
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["show_toast"]),
    isDisabled() {
      if (this.invalid_cnpj || !this.form.cnpj) {
        return true;
      } else if (!this.email && !this.is_resposible) {
        return true;
      }
      return false;
    },
    isDisabledInput(element) {
      return !(this.valid_cnpj && !element);
    },
    handleGetCnpj: _.debounce(function() {
      let { cnpj } = this.form;
      cnpj = cnpj.replace(/[^\d]/g, "");
      if (cnpj?.length === 14) {
        this.getCnpj();
      }
    }, 500),

    getCnpj() {
      this.resetForm();
      this.loadingCnpj = true;
      const cnpj = this.form.cnpj.replace(/[^\d]/g, "");
      fetchCNPJ(cnpj)
        .then((data) => {
          if (data.status !== "ERROR") {
            this.valid_cnpj = true;
            this.invalid_cnpj = false;
            this.entrerpise_data = data;
            this.form = {
              ...this.form,
              cnpj: this.entrerpise_data.cnpj,
              social_reason: this.entrerpise_data.nome,
              fantasy_name: this.entrerpise_data.fantasia,
              segment: this.entrerpise_data.atividade_principal[0].text,
              zip_code: this.entrerpise_data.cep,
              city: this.entrerpise_data.municipio,
              state: this.entrerpise_data.uf,
              place: this.entrerpise_data.logradouro,
              number: this.entrerpise_data.numero,
              complement: this.entrerpise_data.complemento,
              neighborhood: this.entrerpise_data.bairro,
              enterprise_email: this.entrerpise_data.email,
              phone: this.entrerpise_data.telefone,
            };
          } else {
            this.valid_cnpj = false;
            this.invalid_cnpj = true;
            this.resetForm();
          }
          this.loadingCnpj = false;
        })
        .catch(() => {
          this.loadingCnpj = false;
          this.invalid_cnpj = true;
          this.resetForm();
        });
    },
    getZipCode() {
      let zipCode = this.form.zip_code;
      if (zipCode) {
        zipCode = zipCode.replace(/[^\d]/g, "");
        if (zipCode.length === 8) {
          this.loading_zip_code = true;
          api
            .get(`/homologation/get-zip-code/${zipCode}/`)
            .then(({ data }) => {
              if (data.erro) {
                this.invalid_cep = true;
              } else {
                this.invalid_cep = false;
              }
              this.responsible_data = data;
              this.form = {
                ...this.form,
                city: this.responsible_data.localidade,
                state: this.responsible_data.uf,
                place: this.responsible_data.logradouro,
                neighborhood: this.responsible_data.bairro,
              };
              this.loading_zip_code = false;
            })
            .catch(() => {
              this.loading_zip_code = false;
            });
        }
      }
    },
    async handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loadingSubmit = true;
        await api
          .patch(`/register/${this.inboundProcess}/`, this.getFormDataObject())
          .then(({ status }) => {
            this.loadingSubmit = false;
            if (status === 200) {
              this.$emit("update_inbound");
            }
          });
      } else {
        this.show_toast({
          message:
            "Há algum problema com dados cadastrados, verifique e tente novamente!",
          type: "error",
        });
      }
    },
    getFormDataObject() {
      const objectForm = {
        inbound_process: this.inboundProcess,
        email: this.email,
        legal_personnel: {
          id: this.user.id,
          social_reason: this.form.social_reason,
          fantasy_name: this.form.fantasy_name,
          segment: this.form.segment,
          email: this.form.enterprise_email,
          phone: this.form.phone,
          cnpj: this.form.cnpj?.replace(/[^\d]/g, ""),
          address: {
            place: this.form.place,
            number: this.form.number,
            complement: this.form.complement,
            zip_code: this.form.zip_code?.replace(/[^\d]/g, ""),
            neighborhood: this.form.neighborhood,
            city: this.form.city,
            state: this.form.state,
            country: "Brasil",
          },
        },
      };

      if (this.is_resposible || this.user.email === objectForm.email) {
        delete objectForm.email;
      }
      return objectForm;
    },
    resetForm() {
      this.form = {
        cnpj: this.form.cnpj,
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
      };
    },
  },
  mounted() {},
  watch: {
    form_data(value) {
      if (value && value.cnpj) {
        this.form = value;
        this.handleGetCnpj(value.cnpj);
        if (value.email === this.user.email) {
          this.is_resposible = true;
          this.disableCheck = true;
        }
      }
    },
    legal_responsible(legal_responsible) {
      if (!legal_responsible) {
        this.is_resposible_logged = true;
        return;
      }
      if (legal_responsible?.email === this.user.email) {
        this.is_resposible_logged = this.is_resposible = true;
      } else {
        this.is_resposible_logged = this.is_resposible = false;
      }
    },
  },
};
</script>
